<!--模块的头部-->
<template>
  <div style="width: 100%;display: flex;align-items: center;justify-content:space-between;margin: 0;padding: 0">
    <div>{{item.menuName}}</div>
    <div style="width: 90px;display: flex;align-items: center;justify-content: space-between;float: right">
      <Button
        size="large"
        type="text"
        ghost
        style="color: black"
        icon="md-remove"
        @click="$emit('mini',item)"></Button>
      <Button
        v-show="!fullscreen"
        size="large"
        type="text"
        ghost
        style="color: black"
        icon="ios-expand"
        @click="$emit('big',item)"></Button>
      <Button
        v-show="fullscreen"
        size="large"
        style="color: black"
        type="text"
        ghost
        icon="md-expand"
        @click="$emit('small',item)"></Button>
      <Button
        size="large"
        type="text"
        style="color: black"
        ghost
        icon="md-close"
        @click="$emit('closeView',item)"></Button>
    </div>
  </div>
</template>

<script>

  export default {
    name: "FuctionHeader",
    props: {
      fullscreen: {
        type:Boolean,
      },
      menu:{
        type:Array,
      },
      item:{
        type:Object,
      },
    },
    data() {
      return {
      };
    },
    methods:{
    },
  };

</script>

<style scoped>

</style>