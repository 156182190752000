export default function() {
    const menus = [
        {
            "menuName": "智慧大屏",
            "path": "/bigscreen/index",
            img: require("../../assets/resource_monitor_64.png"),
            "code": "1",
            id: 1,
            modal: false,
            width:1000,style:{},
            "icon": "weibao",
        },
        {
            "menuName": "电梯地图",
            "path": "/elevator/ElevatorMap",
            img: require("../../assets/resource_monitor_64.png"),
            id: 2,
            modal: true,
            component: () => import("@/views/elevator/ElevatorMap"),
            width:1000,style:{},
            "icon": "fenbu",
        },

        {
            "menuName": "遥监设备",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            fullscreen: false,
            width:1000,style:{},
            id: 4,
            component: () => import("@/views/device/List"),
        },
        {
            "menuName": "故障记录",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 5,
            component: () => import("@/views/event/FaultList"),
        },
        {
            "menuName": "事件记录",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            fullscreen: false,
            width:1000,style:{},
            id: 6,
            component: () => import("@/views/event/EventList"),
        },

        {
            "menuName": "电梯档案",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 7,
            component: () => import("@/views/elevator/ElevatorList"),
        },
        {
            "menuName": "客户公司",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 8,
            component: () => import("@/views/elevatorUsingCompany/List"),
        },
        {
            "menuName": "客户人员",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            fullscreen: false,
            width:1000,style:{},
            id: 9,
            component: () => import("@/views/elevatorUsingCustomer/List"),
        },
        {
            "menuName": "客户楼盘",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            fullscreen: false,
            width:1000,style:{},
            id: 10,
            component: () => import("@/views/elevatorUsingRealEstate/List"),
        },
        {
            "menuName": "公司管理",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 11,
            component: () => import("@/views/framework/Company"),
        },
        {
            "menuName": "部门管理",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 12,
            component: () => import("@/views/framework/Department"),
        },
        {
            "menuName": "岗位管理",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            fullscreen: false,
            width:1000,style:{},
            id: 13,
            component: () => import("@/views/framework/Post"),
        },
        {
            "menuName": "员工管理",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 14,
            component: () => import("@/views/framework/Employee"),
        },
        {
            "menuName": "项目管理",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 15,
            component: () => import("@/views/project/ProjectList"),
        },
       
        {
            "menuName": "保养工单",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 17,
            component: () => import("@/views/maintenance/maintenance/maintenanceOrder/MaintainOrder"),
        },
        {
            "menuName": "报修与任务",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 18,
            component: () => import("@/views/maintenance/repair/repairPlan/reportRepair"),
        },


        {
            "menuName": "用户管理",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            width:1000,style:{},
            visible: false,
            fullscreen: false,
            id: 20,
            component: () => import("@/views/user/UserList"),
        },
        {
            "menuName": "角色管理",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            width:1000,style:{},
            visible: false,
            fullscreen: false,
            id: 21,
            component: () => import("@/views/role/RoleList"),
        },
        {
            "menuName": "登录日志",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            width:1000,style:{},
            visible: false,
            fullscreen: false,
            id: 22,
            component: () => import("@/views/logs/Logs"),
        },
        {
            "menuName": "省市区",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            width:1000,style:{},
            visible: false,
            fullscreen: false,
            id: 23,
            component: () => import("@/views/basic/District"),
        },
        {
            "menuName": "权限菜单",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            width:1000,style:{},
            visible: false,
            fullscreen: false,
            id: 24,
            component: () => import("@/views/basic/Permissions"),
        },
        {
            "menuName": "电梯制造商/品牌/型号",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 25,
            component: () => import("@/views/basic/BrandAndModal"),
        },
        {
            "menuName": "电梯故障字典",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            width:1000,style:{},
            visible: false,
            fullscreen: false,
            id: 26,
            component: () => import("@/views/basic/Fault"),
        },
        {
            "menuName": "电梯保养字典",
            "code": "3",
            "icon": "xitong",
            width:1000,style:{},
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            fullscreen: false,
            id: 27,
            component: () => import("@/views/basic/Maintenance"),
        },
        {
            "menuName": "电梯远程监控",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 29,
            component: () => import("@/views/elevator/ElevatorRemoteList"),
        },
        {
            "menuName": "电梯数据统计",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 291,
            component: () => import("@/views/elevator/ElevatorDataStatistic"),
        },
        {
            "menuName": "监控页面",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 30,
            show:false,
            component: () => import("@/views/monitor/LiftMonitor"),
        },
        {
            "menuName": "按需维保规则配置",
            "code": "3",
            "icon": "xitong",
            img: require("../../assets/resource_monitor_64.png"),
            modal: true,
            visible: false,
            width:1000,style:{},
            fullscreen: false,
            id: 33,
            component: () => import("@/views/maintenance/MaintenanceConfig"),
        },

    ];
    return menus;
}