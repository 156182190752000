<template>
  <div class="box" :style="{backgroundImage: `url(${bgImgUrl})`}">
    <header class="header">
      <div class="back  step_0" @click="backToDes"></div>
      <div class="appbtn step_1" @click="changeShowAll"></div>
      <ul style="display: flex">
        <li v-for="(item,index) in appList" :key="index" class="appbox" @click="open(item)">
          <img class="appimg" :src="item.img" alt=""/>
        </li>
      </ul>
      <div class="toolBar">
        <Button style="float: right;margin-top: 6px" type="text" class="step_2" @click="goToHistory">返回旧版</Button>
        <el-dropdown trigger="click" :class="myBtn?'myActive':'my'" placement="bottom" @visible-change="visibleChange">
          <div class="step_3" style="width: 26px;height: 39px"></div>
          <!--        <div class="my">-->
          <!--        </div>-->
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>{{username}}</el-dropdown-item>
            <el-dropdown-item divided command="logout" @click.native="setting">设置</el-dropdown-item>
            <el-dropdown-item divided command="logout" @click.native="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </header>

    <content class="content">
      <div
        v-for="(item,index) in menu"
        :key="index"
        style="height: 100px ;width: 100px;margin: 20px;cursor: pointer;text-align: center"
        @contextmenu="showMenu(index,item)"
        @click="navigator(item)">
        <img class="img" :src="item.img" alt=""/>
        <div class="title">
          {{item.menuName}}
        </div>
        <vue-context-menu
          v-if="!showAll"
          v-show="transferIndex===index"
          :context-menu-data="contextMenuData"
          :transfer-index="transferIndex"
          @Handler1="Handler_A(index,item)"
        ></vue-context-menu>
      </div>
    </content>
    <div v-if="showAll" class="popContainer" @click="changeShowAll">
      <div class="menuContent" @click="selctApp">
        <el-row :gutter="20">
          <el-col v-for="(item,index) in menus.filter(v=>v.show!==false)" :key="index" :span="6" class="app">
            <div class="grid-content bg-purple" @click="navigator(item)" @contextmenu="addMenu(index,item)">
              <img class="img" :src="item.img" alt=""/>
              <div class="title">
                {{item.menuName}}
              </div>
              <vue-context-menu
                v-show="transferIndex===index"
                :context-menu-data="addMenuData"
                :transfer-index="transferIndex"
                @Handler2="Handler_B(index,item)"
              ></vue-context-menu>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div>
      <Modal
        v-for="(item,index) in menus"
        :id="'modalView'+item.id"
        :key="index"
        :value="item.visible"
        draggable
        scrollable
        :mask="false"
        :width="item.width"
        :fullscreen="item.fullscreen"
        :footer-hide="true"
        :closable="false"
        :title="item.menuName"
        :transfer="false"
        sticky
        class-name="modal"
        :style="item.style"
      >
        <fuction-header
          slot="header"
          :item="item"
          :fullscreen="item.fullscreen"
          @mini="mini"
          @big="big"
          @small="small"
          @closeView="closeView"></fuction-header>
        <div v-if="item.visible" :style="item.fullscreen?{}:{maxHeight: '600px',overflowX: 'auto'}">
          <component
            :is="item.component"
            :menus="menus"
            :query="query"
            @query="sendQuery"
            @addMenu="addQuickMenu"
            @navigator="navigator"></component>
        </div>
      </Modal>
      <Modal
        v-model="visible"
        draggable
        scrollable
        :mask="false"
        width="800"
        :footer-hide="true"
        :transfer="false"
        sticky
        class-name="modal"
      >
        输入背景图地址<el-input v-model="url"></el-input>

        <el-image :src="url"></el-image>
        <el-divider></el-divider>
        <el-button type="primary" @click="saveImage">保存</el-button>
      </Modal>
    </div>
  </div>
</template>

<script>
  import auth from "@/util/loginUtil";
  import UserList from "@/views/user/UserList";
  import FuctionHeader from "@/components/FuctionHeader";
  import Logs from "@/views/logs/Logs";
  import ElevatorList from "@/views/elevator/ElevatorList";
  import menus from "./menu";
  import router from "@/router/router";
  import introJS from "intro.js";

  export default {
    name: "Index",
    components:{UserList ,FuctionHeader,Logs,ElevatorList},
    data() {
      return {
        modal12:false,
        myBtn:false,
        dialogVisible:true,
        visible:false,
        query:{},
        url:"",
        username:"用户名",
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        showAll:false,//显示全部应用
        menu:[],
        menus:menus(),
        bgImgUrl:require("../../assets/background.jpg"),
        appList:[],
        transferIndex: null, // Show the menu that was clicked
        contextMenuData: {
          menuName: "demo",
          axis: {
            x: null,
            y: null,
          },
          menulists: [
            {
              btnName: "删除快捷方式",
              icoName: "fa fa-home fa-fw",
              fnHandler: "Handler1",
            },
          ],
        },
        addMenuData: {
          menuName: "demo",
          axis: {
            x: null,
            y: null,
          },
          menulists: [
            {
              btnName: "新增至桌面",
              icoName: "fa fa-home fa-fw",
              fnHandler: "Handler2",
            },
          ],

        },
        app:{},
        position:{},
        clientPosition:{},
        modalNode:"",
      };
    },
    mounted() {
      document.oncontextmenu = function() {
        event.returnValue = false;
      };
      this.menu = JSON.parse(localStorage.getItem("menu")) instanceof Array?JSON.parse(localStorage.getItem("menu")):[];
      this.intro = JSON.parse(localStorage.getItem("intro"));
      if (!this.intro) {
        this.setGuide();
      }
      localStorage.setItem("intro",JSON.stringify(true));
      let img = localStorage.getItem("bgpic");
      if (img) {
        this.bgImgUrl=img;
      }
    },
    methods:{
      visibleChange(event) {
        this.myBtn = event;
      },
      logout() {
        auth.logout();
        location.reload();
      },
      changeShowAll() {
        this.showAll=!this.showAll;
      },
      backToDes() {
        this.menus = this.menus.map(item=>{item.visible=false;
                                           return item;});
        this.$Modal.remove();
      },
      selctApp() {
        console.log("select");
      },
      navigator(item) {
        if (item.modal) {
          this.menus = this.menus.map(i=>{
            if (item.query) {
              this.query=item.query;
            }
            if (i.id===item.id) {
              i.visible=true;
              if (this.appList.some(v=>v.id===item.id))  return i;
              this.appList.push(item);
              return i;
            }
            return  i;
          });
        }else if (item.path) {
          window.open("#"+item.path);
        }else if (item.children) {
          window.open("#"+item.children[0].path);
        }
        // router.push();
      },
      sendQuery(item) {
        this.query=item;
      },
      open(item) {
        if (item.modal) {
          this.menus = this.menus.map(i=>{
            if (i.id===item.id) {
              i.visible=true;
              return i;
            }
            return  i;
          });
        }
        // router.push();
      },
      //缩小对话框
      small(item) {
        this.menus = this.menus.map(i=>{
          if (i.id===item.id) {
            i.fullscreen=false;
            i.draggable=true;
            return i;
          }
          return  i;
        });

      },
      //放大对话框
      big(item) {
        this.menus = this.menus.map(i=>{
          if (i.id===item.id) {
            i.fullscreen=true;
            i.draggable=false;
            return i;
          }
          return  i;
        });

      },
      //最小化
      mini(item) {
        this.menus = this.menus.map((i)=>{
          if (i.id===item.id) {
            i.visible=false;
            return i;
          }
          return  i;
        });
      },
      //关闭对话框
      closeView(item) {
        this.menus = this.menus.map((i)=>{
          if (i.id===item.id) {
            i.visible=false;
            return i;
          }
          return  i;
        });
        this.appList.forEach((i,idx)=>{
          if (i.id===item.id) {
            this.appList.splice(idx,1);
          }
        });

      },
      goToHistory() {
        localStorage.setItem("os","old");
        router.push("/");
      },
      showMenu(index) {
        this.transferIndex = index; // tranfer index to child component
        event.preventDefault();
        var x = event.clientX;
        var y = event.clientY;
        this.contextMenuData.axis = {
          x,
          y,
        };
      },
      addMenu(index) {
        this.transferIndex = index; // tranfer index to child component
        event.preventDefault();
        var x = event.clientX;
        var y = event.clientY;
        this.addMenuData.axis = {
          x,
          y,
        };
      },
      Handler_A(index,item) {
        this.menu.forEach((i,idx)=>{
          if (i.id===item.id) {
            this.menu.splice(idx,1);
          }
        });
        localStorage.setItem("menu",JSON.stringify(this.menu));
      },
      Handler_B(index,item) {
        if (this.menu.some(v=>v.id===item.id)) return this.$message.info("该应用已在桌面");
        this.menu.push(item);
        localStorage.setItem("menu",JSON.stringify(this.menu));
      },
      onmouseover(newRect,item) {
        this.app=item;
        const modal = document.getElementById("modalView"+item.id);
        const child = modal.lastChild.lastChild.lastChild;
        this.modalNode = child;
        const positon = child.getBoundingClientRect();
        this.clientPosition = positon;
        if (newRect.pageX-positon.x<20||positon.x+item.width-newRect.pageX<20) {
          this.menus = this.menus.map(i=>{
            if (i.id===item.id) {
              i.style={cursor:"e-resize"};
              return i;
            }
            return  i;
          });
          window.addEventListener("mousedown", this.onmousedown);
        }else {
          this.menus = this.menus.map(i=>{
            if (i.id===item.id) {
              i.style={};
              return i;
            }
            return  i;
          });
          window.removeEventListener("mousedown", this.onmousedown);
          window.removeEventListener("mouseup", this.box1End);
          window.removeEventListener("mousemove", this.onmousemove);
        }

      },
      onmousemove(newRect) {
        this.menus = this.menus.map(i=>{
          if (i.id===this.app.id) {
            this.modalNode.left=newRect.pageX;
            if (this.clientPosition.x+10>this.position.pageX) {
              i.width=this.clientPosition.width+(this.clientPosition.x-newRect.pageX);
            }else  {
              i.width=this.clientPosition.width-(this.clientPosition.x+this.clientPosition.width-newRect.pageX);
            }
            return i;
          }
          return  i;
        });
      },
      onmousedown(newRect) {
        this.position=newRect;
        window.addEventListener("mousemove",this.onmousemove);
        window.addEventListener("mouseup", this.box1End);
      },
      box1End() {
        window.removeEventListener("mousemove", this.onmousemove);
        window.removeEventListener("mouseup", this.box1End);
        window.removeEventListener("mousedown", this.onmousedown);
      },
      setGuide() {
        // 通过id或者class绑定步骤，也可以直接定死在div上面
        let steps = [
          {
            element: ".step_0",
            intro: "返回桌面",
            position: "right",
          },
          {
            element: ".step_1",
            intro: "应用中心：可以展示所有应用!右键点击app可以添加至桌面 👋",
            position: "right",
          },
          {
            element: ".step_2",
            intro: "旧系统：点击可返回旧系统! 👋",
            position: "left",
          },
          {
            element: ".step_3",
            intro: "用户管理",
            position: "left",
          },
        ];

        //引入intro.js

        introJS()
          .setOptions({
            // 我给看的效果图是英文的（默认英文），这里是更换成中文
            prevLabel: "上一步",
            nextLabel: "下一步",
            skipLabel: "跳过",
            doneLabel: "完成",
            // steps步骤，可以写个工具类保存起来
            steps: steps,
          })
          .oncomplete(() => {
            // 需要注意的是，这里用了箭头函数
            // 点击结束按钮后执行的事件
          })
          .onexit(() => {
            // 点击跳过按钮后执行的事件
          })
          .onbeforeexit(() => {
            // 确认完毕之后执行的事件
            // return confirm('Are you sure?')
          }).start();
      },
      saveImage() {
        localStorage.setItem("bgpic",this.url);
        this.visible=false;
        this.$router.go(0);
      },
      setting() {
        this.visible=true;
      },
      addQuickMenu(item) {
        if (this.menu.some(v=>v.menuName===(item.realEstateName+item.buildingName))) {
          this.$message.info("该电梯已在桌面");
        }else {
          this.menu.push({modal:true,id:30,menuName:item.realEstateName+item.buildingName,query:{elevatorId:item.id},img: require("../../assets/resource_monitor_64.png")});
          localStorage.setItem("menu",JSON.stringify(this.menu));
        }

      },
    },
  };
</script>

<style scoped>
 .header{
   /*background-color: #505A64;*/
   height: 39px;
   /*background: repeat-x scroll 0 0 transparent;*/
    display: flex;
   background-image: url("../../assets/taskbar_bg.png");
 }
 .box{
   height: 100vh;
   background-image: url("../../assets/background.jpg");
   background-color: transparent;
   background-position: center center;
   background-repeat: no-repeat;
   background-size: cover;
 }
 .back{
   background: transparent no-repeat;
   background-image: url("../../assets/btnpic.png");
   width: 22px;
   background-position: 0 -96px;
   height: 39px;
 }
 .back:hover{
   background-position: 0 -135px;

 }
 .back:active{
   background-position: 0 -174px;

 }

 .appbtn{
  background-image: url("../../assets/btnpic.png");
   width: 88px;
   background-position: 0 -252px;
   height: 39px;
 }
 .appbtn:hover{

   background-position: 0 -291px;
 }
 .appbtn:active{
   background-position: 0 -330px;
 }
 .toolBar{
   display: flex;
   justify-content: end;
   width: 300px;
   position: absolute;
   right: 0px;
 }
 .my{
   background-image: url("../../assets/btnpic.png");
   width: 26px;
   background-position: 0 -947px;
   display: block;
   float: right;
   margin-right: 30px;
 }
 .myActive{
   background-image: url("../../assets/btnpic.png");
   width: 26px;
   background-position: 0 -1011px;
   display: block;
   float: right;
   margin-right: 30px;
 }
 .my:hover{
   background-position: 0 -979px;

 }
 .logout{
   background-image: url("../../assets/btnpic.png");
   width: 26px;
   background-position: 0 -1011px;
   display: block;
 }
 .content{
   height: calc(100% - 39px);
   /*overflow: hidden;*/
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: start;
   width: 20vh;
 }
 .popContainer {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0, 0, 0, 0.7);
   z-index: 2000;
 }
 .menuContent{
   width: 60%;
   min-height: 80%;
   margin: 120px auto;
   /*background: #E6E6E6;*/
   display: flex;
   flex-wrap: wrap;
   /*overflow: hidden;*/
 }
 .app{
   width: 100px;
   height: 100px;
   margin: 10px;
 }
 .title{
   color: white;
   font-size: 16px;
 }
 .img{
   width: 64px;
   height: 64px;
 }
 .ivu-modal-content>.ivu-modal-header {
   background-color: #348DC1;
   padding: 0px;
   border: 1px solid black;
 }
 .appimg{
   width: 38px;
   height: 39px;
 }
 .appbox{
   margin-left: 2px;
   background-image: url('../../assets/btnpic.png');
   background-position:63px -212px;
   height: 39px;
   width: 64px;
   text-align: center;
   cursor: pointer;
 }
 .ivu-modal-header{
   padding: 0px;
 }
 .modal{
   height: 100px;
   overflow: hidden;
 }
 /*.my:active{*/
 /*  background-position: 0 -1011px;*/
 /*}*/
</style>