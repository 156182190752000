<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="authentication/user-login-log/page">
      <el-button
        v-if="$auth('登录日志新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.userName','登陆人')">
            <el-input v-model.trim="filter.userName	" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.userName','登陆ip')">
            <el-input v-model.trim="filter.loginIp	" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.userName','是否登录成功')">
            <el-select v-model="filter.succeed" placeholder="请选择" clearable>
              <el-option
                v-for="item in option"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="userName" :label="$l('user.userName','登陆人')" align="center"></el-table-column>
      <el-table-column prop="loginIp" :label="$l('user.name','登录ip')" align="center"></el-table-column>
      <el-table-column prop="loginTime" :label="$l('user.time','登录时间')" align="center"></el-table-column>
      <el-table-column prop="loginTime" :label="$l('user.time','登录状况')" align="center">
        <template slot-scope="scope">
          {{scope.row.succeed===true?"成功":"失败"}}
        </template>
      </el-table-column>
      <el-table-column prop="failedRemark" :label="$l('user.time','登录失败备注')" align="center"></el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('登录日志编辑')" type="primary" @click="$refs.editPage.open(scope.row.id,scope.row.userType)">
            {{$l("common.edit", "编辑")}}
          </el-button>
          <el-button v-if="$auth('登录日志删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";

  export default {
    components:{EditPage},
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",
          orderColumnName:"loginTime",
        },
        option:[{
                  label:"是",
                  value:true,
                },
                {
                  label: "否",
                  value: false,
                },
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },

      toggleStatus(row) {
        let statusDesc = row.status === "enabled" ? "禁用" : "启用";
        this.$confirm(`确定${statusDesc + row.phone}吗?`, "提示", {type: "warning"}).then(() => {
          this.$http.put(`users/${row.id}/toggleStatus`).then(() => {
            this.getList(-1);
          });
        });
      },
      resetPassword(row) {
        this.$prompt("请输入新密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: this.$rule.password.pattern,
          inputErrorMessage: "6~20位,不能为纯数字或者纯字母",
        }).then(({value}) => {
          this.$http.put(`users/${row.id}/resetPassword?newPassword=${value}`).then(() => {
            this.$message.success("重置密码成功");
          });
        });
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.userName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("authentication/user-login-log/", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.userName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
